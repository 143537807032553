import Center from "../../assets/images/product/insideLatex/center.webp";
import Left from "../../assets/images/product/insideLatex/left.webp";
import InsideLatex from "../../assets/images/products/insideLatex.webp";
import { categoriesOfPaints } from "../categoryOfPaints";

export const insideLatex = {
  category: [categoriesOfPaints.interior],
  path: "festek_inside_latex",
  name: "FESTEK-INSIDE LATEX",
  header: "Краска интерьерная акриловая\n«FESTEK-INSIDE LATEX»",
  link: "https://drive.google.com/file/d/1Pyjhd0RxY8JP-OgoBvAiJzk1-wHwsmyd/view?usp=sharing",
  purchase: [
    {
      price: "180,00",
      amount: "15 кг",
    },
  ],
  productsPage: {
    title: "Интерьерная «FESTEK-INSIDE LATEX»",
    text: "Глубокоматовая износостойкая для стен и потолков с высокими требованьями к истираемости",
  },
  images: {
    products: InsideLatex,
    product: [Center, Left],
  },
  documentation: {
    description: {
      concept: {
        value:
          "это износостойкая интерьерная краска, которая предназначена для окраски стен и потолков в жилых и административных помещениях с умеренной влажностью и стандартной эксплуатационной нагрузкой (спальни, гостиные, коридоры, холлы, офисные помещения). Допускается нанесение по обоям, предназначенным под покраску и внутри помещений с повышенными требованиями к эксплуатации и санитарно-гигиеническому состоянию.",
        label: "FESTEK «INSIDE LATEX»",
      },

      properties: {
        value: [
          "Обладает превосходными малярными и эксплуатационными характеристиками.",
          "Образует гладкое, ровное, матовое покрытие.",
          "Высокая степенью прочности.",
          "Стойкая к мытью с применением бытовых моющих средств и дезинфицирующих растворов.",
          "Стойкая к частым влажным уборкам.",
          "Не стимулирует рост и развитие микрофлоры.",
          "Покрытие отличается высокой адгезией к основанию и укрывистостью.",
          "Имеет насыщенный белый оттенок.",
        ],
        label: "Преимущество",
      },

      consumption: {
        value:
          "На двухслойное покрытие – 300 - 350 г/м2. Зависит от пористости поверхности, применяемого оборудования, квалификации персонала, погодных условий. При окрашивании фактурных поверхностей (типа: шуба, барашек, короед и т.п.) расход краски увеличивается в 1,5 раза.",
        label: "Расход",
      },

      package: {
        value: "11 литров – 15 килограмм; 20 литров – 25 килограмм.",
        label: "Упаковка",
      },

      suitableSubstrates: {
        value:
          "Подходит для всех видов минеральных поверхностей (бетонные, кирпичные, оштукатуренные, зашпатлеванные, гипсокартонные) и обои (гладкие, тисненые, рельефные, бумажные, виниловые, флизелиновые, стекловолокнистые и другие специально предназначенные или рекомендуемые под покраску).",
        label: "Пригодные подложки",
      },

      coatingSystem: {
        value:
          "Для получения качественного покрытия и снижения расхода краски поверхность обработать грунтовкой «Festek-Grunt» для глубокого проникновения или «Festek-MaxiGrunt», исходя из рекомендаций по применению. Краска готова к применению. При необходимости допускается разбавление водой – не более 5 %, при безвоздушном нанесении - около 10 %",
        label: "Система покрытия",
      },

      colorSolutions: {
        value:
          "Белый + цвета по большинству колеровочных системы. Возможен подбор цвета по вашему образцу. Богатую палитру цветов можете выбрать у нас сайте",
        label: "Цветовые решения",
      },
    },

    applicationTechnology: {
      composition: {
        value:
          "Водная дисперсия акриловых сополимеров, пигменты, наполнители, функциональные добавки, вода.",
        label: "Состав",
      },

      preparationSurfaces: {
        value:
          "Перед применением поверхность очистить от пыли, грязи, разделительных жидкостей, меловых, известковых, непрочно держащихся и несовместимых покрытий (силикатные, масляные и др. ЛКМ). При наличии биопоражений и высолов, удалить их механически, и при необходимости обработать специальными составами. Новые бетонные поверхности грунтовать не ранее, чем через 28 дней, оштукатуренные – не ранее, чем через 7 дней (в соответствии с рекомендациями производителя). При проведении окрасочных работ влажность основания должна быть не более 8 %, влажность воздуха в помещении – 40 - 60%. Глянцевые поверхности перед окраской зашлифовать и обеспылить либо применить адгезионную грунтовку.",
        label: "Подготовка поверхности",
      },

      methodAndConditionsDrawing: {
        value:
          "Работы проводить при температуре окружающего воздуха, краски и окрашиваемой поверхности не ниже 10 °С. Перед применением краску тщательно перемешать и наносить на поверхность: •\u00A0Кисточкой; •\u00A0Валиком; •\u00A0Пневматическим или безвоздушным методом распыления; Упаковку с частично использованным материалом тщательно закрыть, а ее содержимое использовать в наиболее короткий срок. Краска готова к применению. При необходимости допускается разбавление водой – не более 5 %, при безвоздушном нанесении - около 10 %. Время выдержки покрытия до первой влажной уборки – не менее 21 суток. После работы инструмент, пятна краски отмыть теплой водой с мылом до высыхания краски. Краска одного цвета, но различных партий может незначительно отличаться по тону. Для исключения разнооттеночности получаемого покрытия следует выбирать краску одной партии или смешивать необходимое количество материала различных партий в отдельной емкости.",
        label: "Способ и условия нанесения",
      },

      temperature: {
        value:
          "Промежуточная сушка каждого слоя в течение 4 ч при температуре (20±2) °С. При более низкой температуре и/или повышенной влажности время высыхания может увеличиваться до 24 ч. Время выдержки покрытия до первой влажной уборки – не менее 21 суток.",
        label: "Температурный режим, время высыхания",
      },

      endlessApplication: {
        value:
          'При нанесении краскопультом (безвоздушное) рекомендуются след. параметры: угол распыления 60°, диаметр сопла 0,53-0,58 мм (0,021"-0,023" дюйма), Давление 175-180 bar.',
        label: "Безвоздушное нанесение",
      },
    },

    technicalData: [
      {
        qualityIndicators: "Массовая доля нелетучих веществ, %",
        value: "60 - 68",
      },
      {
        qualityIndicators: "Показатель концентрации ионов водорода, рН",
        value: "7,5 - 9,5",
      },
      { qualityIndicators: "Степень перетира, мкм, не более", value: "70" },
      {
        qualityIndicators:
          "Время высыхания до степени 3 при температуре (20 ± 2) °С, ч, не более",
        value: "1",
      },
      {
        qualityIndicators:
          "Стойкость покрытия к статическому воздействию воды при температуре (20 ± 2) °С, ч, не менее",
        value: "24",
      },
      {
        qualityIndicators:
          "Стойкость покрытия к статическому воздействию 0,5 % раствора моющих средств при температуре (35 ± 2) °С, мин, не менее",
        value: "15",
      },
      {
        qualityIndicators:
          "Стойкость покрытия при температуре (20 ± 2) °С к статическому воздействию рабочих дезинфицирующих растворов, мин, не менее",
        value: "",
      },
      {
        qualityIndicators: "- «Гексадекон», «Дескоцид», «Полидез»",
        value: "30",
      },
      {
        qualityIndicators: "- «Триацид»",
        value: "60",
      },
      { qualityIndicators: "- «КДИ»", value: "120" },
      {
        qualityIndicators: "Смываемость пленки, г/м2, не более",
        value: "3,0",
      },
    ],
  },
};
