import Center from "../../assets/images/product/grunt/center.webp";
import Left from "../../assets/images/product/grunt/left.webp";
import Grunt from "../../assets/images/products/grunt.webp";
import { categoriesOfPaints } from "../categoryOfPaints";

export const grunt = {
  category: [categoriesOfPaints.primer],
  path: "festek_grunt",
  name: "FESTEK_GRUNT",
  header: "Грунтовка глубокого проникновения «FESTEK-GRUNT»",
  link: "https://drive.google.com/file/d/15T372uB6eR_pJ512LeHQX3AepQH18FHx/view?usp=sharing",
  purchase: [
    {
      price: "30,00",
      amount: "10 кг – 1к1",
    },
    {
      price: "45,00",
      amount: "10 кг – 1к3",
    },
  ],
  productsPage: {
    title: "Универсальная грунтовка «FESTEK-GRUNT»",
    text: "Готовая к применению или концентрат укрепляет практически все виды поверхностей. Снижает расход краски. Для безупречного финишного покрытия",
  },
  images: {
    products: Grunt,
    product: [Center, Left],
  },
  documentation: {
    description: {
      concept: {
        value:
          "это грунтовка концентрат 1к1 применяется для поверхностного укрепления и пропитки пористых, непрочных и сильновпитывающих минеральных оснований. Внутри и снаружи зданий перед шпатлеванием, оштукатуриванием, окраской, оклейкой обоями, укладкой керамической плитки, приклеиванием теплоизоляционных плит, устройством самонивелирующихся цементных покрытий на полах. Глубоко проникает в основание, снижает и выравнивает впитывающую способность поверхности, укрепляет пылящие основания, обеспечивает прочное сцепление с основанием наносимых отделочных материалов (красок, шпатлевок, штукатурок) и сокращает их расход. Так же есть в исполнение грунтовка концентрат 1 к 3 - 45р за 10 кг.",
        label: "FESTEK-GRUNT",
      },

      consumption: {
        value:
          "50 - 250 г/м2. Зависит от пористости поверхности, применяемого оборудования, квалификации персонала, погодных условий.",
        label: "Расход",
      },

      package: {
        value: "10 литров – 10 килограмм; 20 литров – 20 килограмм.",
        label: "Упаковка",
      },

      suitableSubstrates: {
        value:
          "Бетонные, кирпичные, оштукатуренные, гипсокартонные минеральные, газосиликатные блоки, кирпичные и другие поверхности; допускается нанесение по обоям, предназначенным под покраску.",
        label: "Пригодные подложки",
      },

      colorAndAppearanceCoatings: {
        value: "Внешний вид – после высыхания бесцветный.",
        label: "Цвет и внешний вид покрытия",
      },
    },

    applicationTechnology: {
      composition: {
        value:
          "Водная дисперсия акрилового сополимера с добавлением функциональных добавок.",
        label: "Состав",
      },

      preparationSurfaces: {
        value:
          "Основание должно быть ровным, плотным, прочным, очищенным от высолов, жировых пятен, ржавчины, побелки и т. д. Перед производством работ удалить загрязнения, наплывы бетона и раствора с основания. Металлические поверхности обработать антикоррозионной грунтовкой. Произвести обеспыливание.",
        label: "Подготовка поверхности",
      },

      methodAndConditionsDrawing: {
        value:
          "Работы проводить при t окружающего воздуха, грунтовки и поверхности не ниже 10 °С. Влажность бетонных, каменных, оштукатуренных и зашпатлеванных оснований должна быть не более 8 %, влажность воздуха при фасадных работах – 40-80 %, при интерьерных работах – 40-60 %. Не допускается нанесение и высыхание при неблагоприятных погодных условиях (жара, дождь, туман, выпадение росы, сильный ветер). Перед применением грунтовку тщательно перемешать и наносить на поверхность: •\u00A0Кистью; •\u00A0Валиком; •\u00A0Пневмораспылением.",
        label: "Способ и условия нанесения",
      },

      temperature: {
        value:
          "Работы выполнять при температуре воздуха и основания от +5 до +30 °С и влажности не более 80 %. Выполнение последующих работ производить не ранее чем через 4 часа. Инструмент и свежие загрязнения смыть теплой водой. Схватившаяся грунтовка удаляется механическим путем, Время высыхания 2–4 часа.",
        label: "Температурный режим, время высыхания",
      },

      endlessApplication: {
        value:
          'При нанесении краскопультом (безвоздушное) рекомендуются след. параметры: угол распыления 60°, диаметр сопла 0,53-0,58 мм (0,021"-0,023" дюйма), Давление 175-180 bar.',
        label: "Безвоздушное нанесение",
      },
    },

    technicalData: [
      { qualityIndicators: "Плотность, кг/м3", value: "1,45" },
      {
        qualityIndicators: "Массовая доля нелетучих веществ, %, не менее",
        value: "30,7",
      },
      {
        qualityIndicators: "Водородный показатель (рН)",
        value: "7,8",
      },
      {
        qualityIndicators: "Время высыхания до степени 3, ч, не более",
        value: "1",
      },
    ],

  
  },
};
