export const categoriesOfProducts = {
  all: "Вся продукция",
  interior: "Краска интерьерная",
  facade: "Краска фасадная",
  roofing: "Краска для кровли и цоколей",
  textured: "Краска фактурная",
  primer: "Грунтовки",
};

export const categoriesOfPaints = {
  interior: "interior",
  facade: "facade",
  roofing: "roofing",
  textured: "textured",
  primer: "primer",
};
