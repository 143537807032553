import Center from "../../assets/images/product/maxiGrunt/center.webp";
import Left from "../../assets/images/product/maxiGrunt/left.webp";
import MaxiGrunt from "../../assets/images/products/maxiGrunt.webp";
import { categoriesOfPaints } from "../categoryOfPaints";

export const maxiGrunt = {
  category: [categoriesOfPaints.primer],
  path: "festek_maxi_grunt",
  name: "«FESTEK_MAXIGRUNT»",
  header:
    "Грунтовка адгезионная бетонконтакт «FESTEK-MAXIGRUNT»\nс кварцевым наполнением",
  link: "https://drive.google.com/file/d/15-lsi8GV_AWQ0F9HUDs4DbMrlrFceVsx/view?usp=sharing",
  purchase: [
    {
      price: "60,00",
      amount: "15 кг",
    },
  ],
  productsPage: {
    title: "Адгезионная грунтовка «FESTEK-MAXIGRUNT»",
    text: "Бетонконтакт с кварцевым наполнителем для увеличения сцепления поверхности",
  },
  images: {
    products: MaxiGrunt,
    product: [Center, Left],
  },
  documentation: {
    description: {
      concept: {
        value:
          "это адгезионная грунтовка для создания промежуточного адгезионного (сцепляющего) слоя перед нанесением, содержит кварцевый песок мелкой фракции, придающий загрунтованной поверхности матовость и шероховатость, благодаря чему грунтовка значительно повышает адгезию (сцепление) последующих декоративных покрытий к основанию.",
        label: "FESTEK-GRUNT Бетонконтакт",
      },

      consumption: {
        value:
          "250 - 350 г/м2. Зависит от шероховатости и пористости поверхности, применяемого оборудования, квалификации работника и других факторов и более точно определяется путем нанесения пробного покрытия.",
        label: "Расход",
      },

      package: {
        value: "11 литров – 15 килограмм; 20 литров – 25 килограмм.",
        label: "Упаковка",
      },

      suitableSubstrates: {
        value:
          "Штукатурки и шпатлевки (гипсовых, цементно-известковых и цементных), армирующих и клеящие составы (в том числе для укладки керамической плитки), структурные штукатурки, водно-дисперсионных краски (фактурных и гладких, в т.ч. модифицированных силиконом). Пригодна для грунтования минеральных поверхностей плотной, гладкой структуры: бетонных, оштукатуренных, кирпичных, гипсокартонных. Может использоваться в системах утепления зданий и сооружений. Рекомендуется для нанесения на поверхности по старым прочно держащимся покрытиям, выполненным масляными или алкидными эмалями, дисперсионными или минеральными штукатурками, акриловыми красками. Подходит для грунтования различных видов керамической плитки эмалированной, глазурованной. За счет содержания мелкодисперсного кварцевого песка идеально подходит для работ с фактурными декоративными штукатурками.",
        label: "Пригодные подложки",
      },

      colorAndAppearanceCoatings: {
        value:
          "Цвет покрытия грунтовки – должен находиться в пределах допускаемых отклонений, установленных контрольными образцами цвета. Белая. Внешний вид покрытия – после высыхания грунтовка образует структурную поверхность.",
        label: "Цвет и внешний вид покрытия",
      },
    },

    applicationTechnology: {
      composition: {
        value:
          "Водная дисперсия акрилового сополимера с добавлением функциональных добавок.",
        label: "Состав",
      },

      preparationSurfaces: {
        value:
          "Поверхность очистить от пыли, грязи, разделительных жидкостей, битумных пятен, высолов, меловых, известковых, непрочно держащихся и несовместимых покрытий (силикатные и др. ЛКМ), должен отсутствовать эффект меления. При наличии биопоражений и высолов поверхности очистить механически или при помощи моек высокого давления, при необходимости обработать специальными составами. Глянцевые поверхности зашлифовать и обеспылить. Новые бетонные поверхности грунтовать не ранее, чем через 28 дней, оштукатуренные – не ранее, чем через 7 дней (или в соответствии с рекомендациями производителя). После дождя поверхность должна просохнуть в течение 2 - 3 дней. Не наносить на известковые и известково-цементные поверхности!",
        label: "Подготовка поверхности",
      },

      methodAndConditionsDrawing: {
        value:
          "Работы проводить при t окружающего воздуха, грунтовки и поверхности не ниже 10 °С. Влажность бетонных, каменных, оштукатуренных и зашпатлеванных оснований должна быть не более 8 %, влажность воздуха при фасадных работах – 40-80 %, при интерьерных работах – 40-60 %. Не допускается нанесение и высыхание при неблагоприятных погодных условиях (жара, дождь, туман, выпадение росы, сильный ветер). Перед применением грунтовку тщательно перемешать и наносить на поверхность: •\u00A0Кистью; •\u00A0Валиком; •\u00A0Пневмораспылением.",
        label: "Способ и условия нанесения",
      },

      temperature: {
        value:
          "Время высыхания грунтовки при температуре (20 ± 2) °С и относительной влажности (65 ± 5) % – 2 часа. При более низкой температуре и высокой влажности время высыхания может увеличиваться.",
        label: "Температурный режим, время высыхания",
      },

      endlessApplication: {
        value:
          'При нанесении краскопультом (безвоздушное) рекомендуются след. параметры: угол распыления 60°, диаметр сопла 0,53-0,58 мм (0,021"-0,023" дюйма), Давление 175-180 bar.',
        label: "Безвоздушное нанесение",
      },
    },

    technicalData: [
      {
        qualityIndicators: "Показатель концентрации ионов водорода, рН",
        value: "7,5 – 9,5",
      },
      {
        qualityIndicators: "Массовая доля нелетучих веществ, %",
        value: "59 - 70",
      },
      {
        qualityIndicators:
          "Время высыхания до степени 3 при температуре (20 ± 2) °С, ч, не более",
        value: "2",
      },
      {
        qualityIndicators: "Прочность сцепления с основанием, МПа, не менее:",
        value: "",
      },
      { qualityIndicators: "- грунтовочного покрытия", value: "1,5" },
      {
        qualityIndicators:
          "- комплексного покрытия с использованием грунтовки – по прочности сцепления комплексных покрытий:",
        value: "",
      },
      {
        qualityIndicators: "Краски фасадные водно-дисперсионные",
        value: "1,0",
      },
      {
        qualityIndicators:
          "Краски фасадные ВД фактурные, краски силикатные и полимерцементные",
        value: "0,8",
      },
      {
        qualityIndicators:
          "Штукатурки и шпатлевки полимерные и полимерминеральные для внутренних работ",
        value: "0,4",
      },
      {
        qualityIndicators:
          "Штукатурки полимерные и полимерминеральные для наружных работ",
        value: "0,8",
      },
      {
        qualityIndicators:
          "Шпатлевки полимерные и полимерминеральные для наружных работ",
        value: "0,6",
      },
      {
        qualityIndicators: "Штукатурки минеральные для наружных работ",
        value: "0,5",
      },
      {
        qualityIndicators: "Штукатурки минеральные для внутренних работ",
        value: "0,2",
      },
    ],
  },
};
