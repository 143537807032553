export const extraWidth = 1400;
export const desktopWidth = 1200;
export const laptopWidth = 1024;
export const tabletWidth = 768;
export const smartphoneWidth = 600;
export const mobileWidth = 480;

export const BREAKPOINTS = {
  extra: `(max-width: ${extraWidth}px)`,
  desktop: `(max-width: ${desktopWidth}px)`,
  laptop: `(max-width: ${laptopWidth}px)`,
  tablet: `(max-width: ${tabletWidth}px)`,
  smartphone: `(max-width: ${smartphoneWidth}px)`,
  mobile: `(max-width: ${mobileWidth}px)`,
};
