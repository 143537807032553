import Center from "../../assets/images/product/superWhite/center.webp";
import Left from "../../assets/images/product/superWhite/left.webp";
import SuperWhite from "../../assets/images/products/superWhite.webp";
import { categoriesOfPaints } from "../categoryOfPaints";

export const superWhite = {
  category: [categoriesOfPaints.interior],
  path: "festek_super_white",
  name: "FESTEK-SUPERWHITE",
  header: "Краска интерьерная акриловая\n«FESTEK-SUPERWHITE»",
  link: "https://drive.google.com/file/d/1DJIxhD3XodX7yVQKux_rGBLl6Vo1PCki/view?usp=sharing",
  purchase: [
    {
      price: "180,00",
      amount: "15 кг",
    },
  ],
  productsPage: {
    title: "Интерьерная «FESTEK-SUPERWHITE»",
    text: "Глубокоматовая потолочная краска с высокой степенью белизны",
  },
  images: {
    products: SuperWhite,
    product: [Center, Left],
  },
  documentation: {
    description: {
      concept: {
        value:
          "это интерьерная потолочная краска, для окраски потолков и стен в жилых помещениях с умеренной влажностью. Полная укрывистость набирается по мере высыхания покрытия.",
        label: "FESTEK «SUPERWHITE»",
      },

      properties: {
        value: [
          "Обладает превосходными малярными характеристиками.",
          "Покрытие отличается высокой адгезией к основанию и укрывистостью.",
          "Имеет насыщенный белый оттенок.",
          "Образует глубоко-матовое снежно-белое покрытие, обладающее высокой укрывистостью.",
          "Не подвержена пожелтению.",
          "Не мелит, не разбрызгивается, легко наносится.",
          "Устойчива к сухой уборке.",
        ],
        label: "Преимущество",
      },

      consumption: {
        value:
          "350-400 г/м2 на двухслойное покрытие. Зависит от состояния и подготовки поверхности, применяемого оборудования, квалификации работника и более точно определяется путем нанесения пробного покрытия. При окрашивании фактурных поверхностей (типа: шуба, барашек, короед и т.п.) расход краски увеличивается в 1,5 раза.",
        label: "Расход",
      },

      package: {
        value: "11 литров – 15 килограмм; 20 литров – 25 килограмм.",
        label: "Упаковка",
      },

      suitableSubstrates: {
        value:
          "Бетонные, оштукатуренные, зашпатлеванные, гипсокартонные, кирпичные и другие минеральные поверхности.",
        label: "Пригодные подложки",
      },

      coatingSystem: {
        value:
          "Для получения качественного покрытия и снижения расхода краски поверхность обработать грунтовкой «Festek-Grunt» для глубокого проникновения или «Festek-MaxiGrunt», исходя из рекомендаций по применению. Краска готова к применению. При необходимости допускается разбавление водой – не более 5 %, при безвоздушном нанесении - около 10 %",
        label: "Система покрытия",
      },

      colorSolutions: {
        value:
          "Белый + цвета по большинству колеровочных системы. Возможен подбор цвета по вашему образцу. Богатую палитру цветов можете выбрать у нас сайте",
        label: "Цветовые решения",
      },
    },

    applicationTechnology: {
      composition: {
        value:
          "Водная дисперсия акриловых сополимеров, пигменты, наполнители, функциональные добавки, вода.",
        label: "Состав",
      },

      preparationSurfaces: {
        value:
          "Поверхность очистить от пыли, грязи, разделительных жидкостей, меловых, известковых, непрочно держащихся и несовместимых покрытий (силикатные, масляные и т.п.), должен отсутствовать эффект меления. Биопоражения и высолы удалить механически, при необходимости обработать специальными составами. Глянцевые основания зашлифовать и обеспылить. Новые бетонные поверхности окрашивать не ранее, чем через 28 дней, оштукатуренные полимерминеральными составами - не ранее, чем через 7 дней после их естественного высыхания (или согласно рекомендациям производителя). Металлические части (арматура, шляпки гвоздей и т.д.) покрыть антикоррозионной грунтовкой. Не наносить на известковые и известково-цементные поверхности.",
        label: "Подготовка поверхности",
      },

      methodAndConditionsDrawing: {
        value:
          "Работы проводить при температуре окружающего воздуха, краски и окрашиваемой поверхности не ниже 10 °С. Перед применением краску тщательно перемешать и наносить на поверхность: •\u00A0Кисточкой; •\u00A0Валиком; •\u00A0Пневматическим или безвоздушным методом распыления; Упаковку с частично использованным материалом тщательно закрыть, а ее содержимое использовать в наиболее короткий срок. Краска готова к применению. При необходимости допускается разбавление водой – не более 5 %, при безвоздушном нанесении - около 10 %. Время выдержки покрытия до первой влажной уборки – не менее 21 суток. После работы инструмент, пятна краски отмыть теплой водой с мылом до высыхания краски. Краска одного цвета, но различных партий может незначительно отличаться по тону. Для исключения разнооттеночности получаемого покрытия следует выбирать краску одной партии или смешивать необходимое количество материала различных партий в отдельной емкости.",
        label: "Способ и условия нанесения",
      },

      temperature: {
        value:
          "Промежуточная сушка каждого слоя в течение 4 ч при температуре (20±2) °С. При более низкой температуре и/или повышенной влажности время высыхания может увеличиваться до 24 ч. Время выдержки покрытия до первой влажной уборки – не менее 21 суток.",
        label: "Температурный режим, время высыхания",
      },

      endlessApplication: {
        value:
          'При нанесении краскопультом (безвоздушное) рекомендуются след. параметры: угол распыления 60°, диаметр сопла 0,53-0,58 мм (0,021"-0,023" дюйма), Давление 175-180 bar.',
        label: "Безвоздушное нанесение",
      },
    },

    technicalData: [
      {
        qualityIndicators: "Массовая доля нелетучих веществ, %",
        value: "60 - 68",
      },
      {
        qualityIndicators: "Показатель концентрации ионов водорода, pH",
        value: "7,0 - 9,5",
      },
      {
        qualityIndicators: "Степень перетира, мкм, не более",
        value: "70",
      },
      {
        qualityIndicators:
          "Время высыхания до степени 3 при температуре (20±2) °С, ч, не более",
        value: "1",
      },
    ],
  },
};
